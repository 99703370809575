import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { useState } from 'react'
import Layout from '../../components/Layout'
import ClipLoader from 'react-spinners/ClipLoader'
import { Link } from 'gatsby'
import Arrow from '../../images/link-arrow.svg'
import CookieBar from "../../components/CookieBar";

export default function ForgotPassword() {
  const [error] = useState()
  const [status, setStatus] = useState('')
  const [email, setEmail] = useState('')
  const [step, setStep] = useState(1)

  const [sendPasswordReset] = useMutation(SEND_PASSWORD_RESET, {
    onCompleted: () => {
      console.log('Password reset email was sent');
      setStep(2)
      setStatus('')
    },
    onError: (err) => {
      console.log(err.message);
      setStatus('')
    }
  })

  const submit = (e) => {
    e.preventDefault();
    console.log(email);
    if (email === '') return;
    setStatus('resolving')
    sendPasswordReset({ variables: { input: { email: email } } }).then(() => e.target.reset())
  }

  return (
    <Layout>
      <div className="forgot-password">
        {step === 1 &&
          <Link to="/login" className="hover-underline">
            <img src={Arrow} alt="arrow" />
            Tillbaka
          </Link>
        }
        <h2>Glömt lösenord?</h2>
        {step === 1 &&
          <form onSubmit={submit}>
            <div className="form-row">
              <label htmlFor="email">E-postadress</label>
              <input onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" autoComplete="off" required />
            </div>
            <button type="submit" disabled={status === 'resolving'}>Återställ</button>
            <div className="messages">
              {status === 'resolving' &&
                <div className="text-center spinner">
                  <ClipLoader size={22} color={"#ffffffbb"} />
                </div>
              }
              {error && (
                <div className="error-notice text-center">
                  <p>{error}</p>
                </div>
              )}
            </div>
          </form>
        }
        {step === 2 &&
          <div>
            <p>Ett e-postmeddelande skickas till dig med en länk för att återställa lösenordet.</p>
            <Link to="/login" className="btn">
              <button>
                Tillbaka
              </button>
            </Link>
          </div>
        }
      </div>
      {/* <CookieBar /> */}
    </Layout>
  )
}

const SEND_PASSWORD_RESET = gql`
  mutation($input: KbSendPasswordResetEmailInput!) {
    kbSendPasswordResetEmail(input: $input) {
      clientMutationId
    }
  }
`
